footer {
  padding-block: 2.5rem;

  .footer__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    .footer__socials {
      display: flex;
      gap: 3rem;

      a {
        color: $mainAccent;
        transition: color 0.15s ease-in-out;

        &:hover,
        &:focus-visible {
          color: $altAccent;
        }
      }
    }

    p {
      text-align: center;
      font-size: 14px;
    }
  }
}

@media screen and (min-width: 48rem) {
  footer {
    .footer__wrapper {
      p {
        font-size: 1rem;
      }
    }
  }
}

@media screen and (min-width: 64rem) {
  footer {
    padding-block: 3rem;
    .footer__wrapper {
      p {
        font-size: 1.125rem;
      }
    }
  }
}

.hero {
  min-height: calc(100vh - 6rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(/public/assets/sr.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  .hero__wrapper {
    h1 {
      color: $altAccent;
      margin-bottom: 0.5rem;
    }

    h2 {
      color: $mainAccent;
      font-size: 3rem;
      margin-bottom: 0.5rem;
    }

    h3 {
      font-size: 2.5rem;
      margin-bottom: 1.5rem;
    }

    p {
      line-height: 2rem;
      font-size: 1.25rem;

      em {
        color: $altAccent;
      }
    }
  }
}

@media screen and (min-width: 48rem) {
  .hero {
    min-height: calc(100vh - 6.5rem);

    .hero__wrapper {
      h1 {
        font-size: 1.25rem;
      }

      h2 {
        font-size: 3.5rem;
        margin-bottom: 1rem;
      }

      h3 {
        font-size: 3rem;
        margin-bottom: 2rem;
      }

      p {
        font-size: 1.5rem;
      }
    }
  }
}

@media screen and (min-width: 64rem) {
  .hero {
    min-height: calc(100vh - 7.5rem);

    .hero__wrapper {
      max-width: 85.375rem;

      h1 {
        font-size: 1.5rem;
      }

      h2 {
        font-size: 4rem;
      }
    }
  }
}

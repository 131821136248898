.projects {
  padding-top: 2rem;
  margin-bottom: 5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__wrapper {
    display: flex;
    flex-direction: column;

    .projects__title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;
      margin-bottom: 2rem;

      &::after {
        content: "";
        flex: 1 1 0;
        height: 1px;
        width: 100%;
        background-color: $mainAccent;
      }

      h4 {
        font-size: 2rem;

        em {
          color: $mainAccent;
        }
      }
    }

    p {
      margin-bottom: 2rem;
      line-height: 1.5rem;
    }

    .inner__wrapper {
      display: flex;
      flex-direction: column;
      gap: 3rem;

      .project {
        background-color: rgba(15, 255, 199, 0.05);
        padding: 1rem;

        h5 {
          font-size: 1.5rem;
          color: $mainAccent;
          margin-bottom: 1rem;
          line-height: 2rem;
        }

        p {
          margin-bottom: 1rem;
        }

        &__tags {
          color: $altAccent;
          display: flex;
          align-items: center;
          gap: 0.75rem;
          margin-bottom: 1.5rem;
        }

        .icon__wrapper {
          display: flex;
          gap: 2rem;

          a {
            color: $mainAccent;
            transition: color 0.15s ease-in-out;

            &:hover,
            &:focus-visible {
              color: $altAccent;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 48rem) {
  .projects {
    margin-bottom: 6.25rem;

    &__wrapper {
      max-width: 64rem;

      .projects__title {
        h4 {
          font-size: 2.5rem;
        }
      }

      p {
        font-size: 1.125rem;
        line-height: 1.75rem;
      }

      .inner__wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;

        .project {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }
  }
}

@media screen and (min-width: 64rem) {
  .projects {
    margin-bottom: 7.5rem;

    &__wrapper {
      max-width: 85.375rem;

      .inner__wrapper {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }
}

.about {
  padding-top: 2rem;
  margin-bottom: 5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__wrapper {
    display: flex;
    flex-direction: column;

    .about__title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;
      margin-bottom: 2rem;

      &::after {
        content: "";
        flex: 1 1 0;
        height: 1px;
        width: 100%;
        background-color: $mainAccent;
      }

      h4 {
        font-size: 2rem;

        em {
          color: $mainAccent;
        }
      }
    }

    .inner__wrapper {
      .text__wrapper {
        p {
          line-height: 1.5rem;
          margin-bottom: 2rem;

          em {
            color: $mainAccent;
          }
        }
      }

      img {
        margin: 0 auto;
      }
    }
  }
}

@media screen and (min-width: 48rem) {
  .about {
    margin-bottom: 6.25rem;

    &__wrapper {
      max-width: 64rem;

      .about__title {
        h4 {
          font-size: 2.5rem;
        }
      }

      .inner__wrapper {
        .text__wrapper {
          p {
            font-size: 1.125rem;
            line-height: 1.75rem;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 64rem) {
  .about {
    margin-bottom: 7.5rem;

    &__wrapper {
      max-width: 85.375rem;

      .inner__wrapper {
        display: flex;
        align-items: center;

        .text__wrapper {
          p {
            &:last-child {
              margin-bottom: 0rem;
            }
          }
        }

        img {
          width: 50%;
        }
      }
    }
  }
}
